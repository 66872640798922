import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageProps } from 'gatsby-image';

import Layout from 'molecules/Layout';
import DrinkMenu from 'organisms/DrinkMenu';

import 'styles/pages/getraenkekarte.scss';
import { Carte2, Additive } from 'server/src/app/carte/types';

export const DRINK_MENU_QUERY = graphql`
  query DrinkMenuQuery {
    logo: file(relativePath: { eq: "logos/thamarai-getraenkekarte-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hugo: file(
      relativePath: { eq: "products/hugo/hugo-by-thamarai-getraenkekarte.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aperolSpritz: file(
      relativePath: {
        eq: "products/aperol-spritz/aperol-spritz-by-thamarai-getraenkekarte.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    berryFizz: file(
      relativePath: {
        eq: "products/berry-fizz/berry-fizz-by-thamarai-getraenkekarte.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mangoMaiTai: file(
      relativePath: {
        eq: "products/mango-mai-tai/mango-mai-tai-by-thamarai-getraenkekarte.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mangoMojito: file(
      relativePath: {
        eq: "products/mango-mojito/mango-mojito-by-thamarai-getraenkekarte.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allAdditive {
      nodes {
        id
        label
        position
      }
    }
    carte(id: { eq: "getrankekarte" }) {
      id
      updatedAt
      buckets {
        id
        title
        position
        offers {
          id
          product {
            ... on Beverage {
              id
              title
              subtitle
              maker
              description
              tags
              year
              origin
              additiveIds
            }
          }
          active
          position
          quotes {
            id
            price
            position
            description
          }
          variants {
            id
            title
          }
        }
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageProps;
  };
  carte: Carte2;
  hugo: {
    childImageSharp: GatsbyImageProps;
  };
  aperolSpritz: {
    childImageSharp: GatsbyImageProps;
  };
  berryFizz: {
    childImageSharp: GatsbyImageProps;
  };
  mangoMaiTai: {
    childImageSharp: GatsbyImageProps;
  };
  mangoMojito: {
    childImageSharp: GatsbyImageProps;
  };
  allAdditive: {
    nodes: Additive[];
  };
}

interface Props {}

const DrinkMenuPage: React.FC<Props> = () => {
  const {
    logo,
    carte,
    hugo,
    aperolSpritz,
    berryFizz,
    mangoMaiTai,
    mangoMojito,
    allAdditive: { nodes: additives },
  } = useStaticQuery<Data>(DRINK_MENU_QUERY);

  return (
    <Layout
      className="DrinkMenuPage"
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/getraenkekarte"
          canonical="/getraenkekarte"
          title="Getränkekarte"
          lang="de-DE"
          description="Das Mango Mai Tai und der Mango Mojito sind unsere Signature Cocktails. Wir führen hausgemachten Lassi, Biere von Erdinger und Kaffee vom Kaffeehaus Hagen."
        />
      )}
      header={() => (
        <Layout.Header
          title="Getränkekarte"
          subtitle="Alkoholfreie Getränke, Exotische Säfte, Signature Cocktails und Biere aus der Region"
          image={logo.childImageSharp}
          alt="Exklusives Logo für die Getränkekarte"
        />
      )}
    >
      <DrinkMenu
        carte={carte}
        additives={additives}
        images={{
          hugo: hugo.childImageSharp,
          aperolSpritz: aperolSpritz.childImageSharp,
          berryFizz: berryFizz.childImageSharp,
          mangoMaiTai: mangoMaiTai.childImageSharp,
          mangoMojito: mangoMojito.childImageSharp,
        }}
      />
    </Layout>
  );
};

export default DrinkMenuPage;
