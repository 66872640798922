import React from 'react';
import { Bucket2, Additive } from 'server/src/app/carte/types';

import Title from 'atoms/Title';

export interface Props {
  bucket: Bucket2;
  additives: Additive[];
  className?: string;
  quantity0?: string;
  quantity1?: string;
  quantity2?: string;
}

const PriceGrid: React.FC<Props> = ({
  bucket,
  additives,
  className,
  quantity0,
  quantity1,
  quantity2,
}) => {
  const heading0 = quantity0;
  const heading1 = quantity1 === '1x' ? '' : quantity1;
  const heading2 = quantity2 === '1x' ? '' : quantity2;
  const additivesMap: Record<string, Additive> = additives.reduce(
    (prev, curr) => ({ ...prev, [curr.id]: curr }),
    {}
  );

  return (
    <div
      className={`DrinkMenu__PriceGrid PriceGrid ${className ? className : ''}`}
    >
      <Title>{bucket.title}</Title>
      <ul className="PriceGrid__list">
        <li className="PriceGrid__item">
          <div className="PriceGrid__quantity PriceGrid__quantity--0 PriceGrid__heading">
            {heading0}
          </div>
          <div className="PriceGrid__quantity PriceGrid__quantity--1 PriceGrid__heading">
            {heading1}
          </div>
          <div className="PriceGrid__quantity PriceGrid__quantity--2 PriceGrid__heading">
            {heading2}
          </div>
        </li>
        {bucket.offers
          .sort((a, b) => a.position - b.position)
          .map(offer => {
            const additives = offer.product.additiveIds
              ?.map(id => additivesMap[id].position)
              .sort((a, b) => a - b)
              .join(',');

            const quote1 = offer.quotes.find(q => q.description === quantity1);
            const price1 =
              quote1 && (quote1.price / 100).toFixed(2).replace('.', ',');

            const quote2 = offer.quotes.find(q => q.description === quantity2);
            const price2 =
              quote2 && (quote2.price / 100).toFixed(2).replace('.', ',');

            const special = offer.variants.find(
              variant => variant.title === 'special'
            );

            return (
              <li
                key={offer.id}
                className={`PriceGrid__item ${
                  special ? 'PriceGrid__item--special' : ''
                }`}
              >
                <div className="PriceGrid__quantity PriceGrid__quantity--0">
                  <div className="PriceGrid__label">
                    {offer.product.title}
                    {additives && (
                      <div className="PriceGrid__additives">{additives}</div>
                    )}
                  </div>
                </div>
                <div className="PriceGrid__quantity PriceGrid__quantity--1">
                  {price1}
                </div>
                <div className="PriceGrid__quantity PriceGrid__quantity--2">
                  {price2}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default PriceGrid;
