import React from 'react';
import Button, { ButtonSize } from '@soosap/sushi/Button';

import Container from 'atoms/Container';
import PDFIcon from 'icons/PDF';
import { Carte2, Additive } from 'server/src/app/carte/types';
import PriceGrid from './components/PriceGrid';
import PhotoGrid from './components/PhotoGrid';
import { GatsbyImageProps } from 'gatsby-image';

const isClient = typeof window !== 'undefined';

export interface Props {
  carte: Carte2;
  additives: Additive[];
  images: Record<string, GatsbyImageProps>;
}

const DrinkMenu: React.FC<Props> = ({ carte, additives, images }) => {
  const { hugo, aperolSpritz, berryFizz, mangoMaiTai, mangoMojito } = images;

  const aperitifBucket = carte.buckets.find(bucket => bucket.id === 'aperitif');
  const cocktailsBucket = carte.buckets.find(
    bucket => bucket.id === 'cocktails'
  );
  const softdrinksBucket = carte.buckets.find(
    bucket => bucket.id === 'softdrinks'
  );

  const juicesBucket = carte.buckets.find(bucket => bucket.id === 'safte');
  const waterBucket = carte.buckets.find(bucket => bucket.id === 'wasser');
  const lassiBucket = carte.buckets.find(bucket => bucket.id === 'lassi');
  const bottledBeerBucket = carte.buckets.find(
    bucket => bucket.id === 'flaschenbiere'
  );
  const draftBeerBucket = carte.buckets.find(
    bucket => bucket.id === 'biere-vom-fass'
  );
  const coffeeBucket = carte.buckets.find(bucket => bucket.id === 'kaffee');
  const teaBucket = carte.buckets.find(bucket => bucket.id === 'tee');

  const additivesMap: Record<string, Additive> = additives.reduce(
    (prev, curr) => ({ ...prev, [curr.id]: curr }),
    {}
  );

  const reducedMap: Record<string, Additive> = carte.buckets.reduce(
    (prev, currBucket) => {
      const basketAdditives = currBucket.offers.reduce((prev, currOffer) => {
        return {
          ...prev,
          ...(currOffer.product.additiveIds || [])
            .map(id => additivesMap[id])
            .reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
        };
      }, {});

      return { ...prev, ...basketAdditives };
    },
    {}
  );

  const additivesStrings = Object.values(reducedMap)
    .sort((a, b) => a.position - b.position)
    .map(additive => `${additive.position}: ${additive.label}`);

  return (
    <Container className={`DrinkMenu`}>
      <div className="DrinkMenu__download">
        <Button
          link
          icon={PDFIcon}
          size={ButtonSize.BIG}
          title="Herunterladen"
          href="/THAMARAI-Restaurant-Getränkekarte.pdf"
          download
          onClick={() => {
            if (isClient) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'call-to-action',
                category: 'Carte',
                action: 'Download',
                label: 'Getränkekarte',
              });
            }
          }}
        />
      </div>
      <div className="DrinkMenu__buckets">
        {aperitifBucket && (
          <PhotoGrid
            key="aperitif"
            className="DrinkMenu__aperitif"
            bucket={aperitifBucket}
            images={{ hugo, aperolSpritz, berryFizz }}
          />
        )}
        {cocktailsBucket && (
          <PhotoGrid
            key="cocktails"
            className="DrinkMenu__cocktails"
            bucket={cocktailsBucket}
            images={{ mangoMaiTai, mangoMojito }}
          />
        )}
        {softdrinksBucket && (
          <PriceGrid
            key="softdrinks"
            className="DrinkMenu__softdrinks"
            bucket={softdrinksBucket}
            additives={additives}
            quantity1="0,2l"
            quantity2="0,4l"
          />
        )}
        {juicesBucket && (
          <PriceGrid
            key="juices"
            className="DrinkMenu__juices"
            bucket={juicesBucket}
            additives={additives}
            quantity1="0,2l"
            quantity2="0,4l"
          />
        )}
        {waterBucket && (
          <PriceGrid
            key="water"
            className="DrinkMenu__water"
            bucket={waterBucket}
            additives={additives}
            quantity1="0,25l"
            quantity2="0,75l"
          />
        )}
        {lassiBucket && (
          <PriceGrid
            key="lassi"
            className="DrinkMenu__lassi"
            bucket={lassiBucket}
            additives={additives}
            quantity0={lassiBucket.offers[0].product.description}
            quantity2="0,2l"
          />
        )}
        {bottledBeerBucket && (
          <PriceGrid
            key="bottled-beer"
            className="DrinkMenu__bottled-beer"
            bucket={bottledBeerBucket}
            additives={additives}
            quantity1="0,3l"
            quantity2="0,5l"
          />
        )}
        {draftBeerBucket && (
          <PriceGrid
            key="draft-beer"
            className="DrinkMenu__draft-beer"
            bucket={draftBeerBucket}
            additives={additives}
            quantity2="0,5l"
          />
        )}
        {coffeeBucket && (
          <PriceGrid
            key="coffee"
            className="DrinkMenu__coffee"
            bucket={coffeeBucket}
            additives={additives}
            quantity2="1x"
          />
        )}
        {teaBucket && (
          <PriceGrid
            key="tea"
            className="DrinkMenu__tea"
            bucket={teaBucket}
            additives={additives}
            quantity2="1x"
          />
        )}
      </div>

      <div className="DrinkMenu__all-additives">
        {additivesStrings.map((str, index) => (
          <span key={str} className="DrinkMenu__additive">
            {str}
            {index !== additivesStrings.length - 1 ? ', ' : ''}
          </span>
        ))}
      </div>
    </Container>
  );
};

export default DrinkMenu;
