import React from 'react';
import Img, { GatsbyImageProps } from 'gatsby-image';
import camelCase from 'lodash.camelcase';

import { Bucket2 } from 'server/src/app/carte/types';
import Title from 'atoms/Title';

export interface Props {
  bucket: Bucket2;
  className?: string;
  images: Record<string, GatsbyImageProps>;
}

const PhotoGrid: React.FC<Props> = ({ bucket, className, images }) => {
  const imagesCount = Object.keys(images).length;

  return (
    <div
      className={`DrinkMenu__PhotoGrid PhotoGrid ${className ? className : ''}`}
    >
      <Title>{bucket.title}</Title>
      <ul className={`PhotoGrid__images PhotoGrid__images--${imagesCount}`}>
        {bucket.offers
          .sort((a, b) => a.position - b.position)
          .map(offer => {
            const price = offer.quotes.find(q => q.id === '1-x')?.price;
            return (
              <li key={offer.id} className="PhotoGrid__item">
                <div className="PhotoGrid__item-inner">
                  <div className="PhotoGrid__title">{offer.product.title}</div>
                  <Img
                    className="PhotoGrid__img"
                    fluid={images[camelCase(offer.product.title)].fluid}
                    alt={`Hausgemachter ${offer.product.title}`}
                    title={`Hausgemachter ${offer.product.title}`}
                  />
                  <div className="PhotoGrid__description">
                    {offer.product.description}
                    {price && (
                      <span className="PhotoGrid__price">
                        {(price / 100).toFixed(2)}
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default PhotoGrid;
